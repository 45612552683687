//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
////import CategoryPath from '@/components/Category/Path'
////import { dateRangeDays } from '@/utils/dates'

export default {
  ////components: { DataTable, CategoryPath },
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("category/getCategoryKeywords", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      //const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2)
      return [{
        title: "要求",
        show: true,
        name: "keyword",
        width: 440,
        filter: "text",
        type: "text"
      }, {
        title: "频率" + (this.reportSettings.mp === "wb" ? "WB" : "OZON"),
        show: true,
        name: "shows",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "根据要求提供结果",
        show: true,
        name: "tot_keyword_products",
        ////type: "number",
        width: 140,
        filter: "numberRange",
        type: "link",
        getLink: item => {
          return {
            href: item.keyword_mp_search_link,
            text: item.tot_keyword_products,
            target: "_blank"
          };
        }
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};